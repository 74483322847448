import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../css/LandingPage.css';
function LandingPage() {
    const navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault();

        navigate('/home');
    };
    return (
        <div className="ring">
            <i style={{ '--clr': '#00ff0a' }}></i>
            <i style={{ '--clr': '#ff0057' }}></i>
            <i style={{ '--clr': '#fffd44' }}></i>
            <div className="login">
                <h2>MindMellow</h2>

                <form onSubmit={handleLogin}>
                <div className="inputBx">
                    <input type="submit" value="Login" />
                </div>
                </form>
            </div>
        </div>
    );
}

export default LandingPage
