import React, { useEffect } from 'react';
import '../css/AnimatedButton.css'; // Import the CSS file

const AnimatedButton = ({ text }) => {
    useEffect(() => {
        const buttons = document.querySelectorAll('.button');
        buttons.forEach(button => {
            let div = document.createElement('div');
            let letters = button.textContent.trim().split('');

            function elements(letter, index, array) {
                let element = document.createElement('span');
                let part = (index >= array.length / 2) ? -1 : 1;
                let position = (index >= array.length / 2) ? array.length / 2 - index + (array.length / 2 - 1) : index;
                let move = position / (array.length / 2);
                let rotate = 1 - move;

                element.innerHTML = !letter.trim() ? '&nbsp;' : letter;
                element.style.setProperty('--move', move);
                element.style.setProperty('--rotate', rotate);
                element.style.setProperty('--part', part);

                div.appendChild(element);
            }

            letters.forEach(elements);

            button.innerHTML = div.outerHTML;

            button.addEventListener('mouseenter', () => {
                if (!button.classList.contains('out')) {
                    button.classList.add('in');
                }
            });

            button.addEventListener('mouseleave', () => {
                if (button.classList.contains('in')) {
                    button.classList.add('out');
                    setTimeout(() => button.classList.remove('in', 'out'), 950);
                }
            });
        });
    }, []);

    return (
        <button className="button alternative">
            {text}
        </button>
    );
};

export default AnimatedButton;