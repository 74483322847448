import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import PropertyList from './pages/PropertyList';
import PropertyDetail from './pages/PropertyDetail';
import Footer from './components/Footer';
import PageTransition from './components/PageTransition';
import LandingPage from './pages/LandingPage';
import NewCity from './pages/NewCity';

const App = () => {
  const location = useLocation();
  const hideNavAndFooter = location.pathname === '/';

  return (
    <>
      {!hideNavAndFooter && <Navbar />}
      <PageTransition>
        <Routes location={location} key={location.pathname}>
          <Route path="/home" element={<Home />} />
          <Route path="/properties" element={<PropertyList />} />
          <Route path="/properties/:id" element={<PropertyDetail />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/newcity" element={<NewCity />} />
        </Routes>
      </PageTransition>
      {!hideNavAndFooter && <Footer />}
    </>
  );
};

export default App;